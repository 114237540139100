<template>
  <div class="m-3" style="min-height: 500px;">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('routePlan.alternates') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close', {cancel:true})" href="javascript:void(0)"
           class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <h6>{{ $t('routePlan.firstAlternate') }}</h6>
        <div class="row align-items-center">
          <div class="col-10">
            <vue-typeahead-bootstrap
              @hit="$e => change('first', $e)"
              v-model="alternate.first"
              :data="airports"
              :disabledValues="(alternate.second ? [alternate.second.code] : [])"
              :serializer="item => item.code"
            />
          </div>
          <div class="col-2">
            <a @click="remove('first')" href="javascript:void(0)"> {{ $t('routePlan.remove') }}</a>
          </div>
        </div>

        <p class="mt-2" v-if="selectedAlternate.first != null">{{ $t('routePlan.selectedAlternate') }}:
          {{ selectedAlternate.first.code }}</p>
      </div>

      <div class="col-12">
        <h6>{{ $t('routePlan.secondAlternate') }}</h6>
        <div class="row align-items-center">
          <div class="col-10">
            <vue-typeahead-bootstrap
							@hit="$e => change('second', $e)"
              v-model="alternate.second"
              :data="airports"
              :disabledValues="(alternate.first ? [alternate.first.code] : [])"
              :serializer="item => item.code"
            />
          </div>
          <div class="col-2">
            <a @click="remove('second')" href="javascript:void(0)"> {{ $t('routePlan.remove') }}</a>
          </div>
        </div>
        <p class="mt-2" v-if="selectedAlternate.second != null">{{ $t('routePlan.selectedAlternate') }}:
          {{ selectedAlternate.second.name }}</p>
      </div>


      <div class="col-12 text-right mt-3">
        <a v-on:click="$emit('close',{selectedAlternate:selectedAlternate})" href="javascript:void(0)"
           class="btn btn-secondary text-center">
          <font-awesome-icon class="m-auto" icon="check-circle"/>
          {{ $t('routePlan.unitOk') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {LOCAL_STORAGE_SERVICE, STORAGE_KEY} from "@/plugins/utils";

export default {
  name: "RoutePlanAlternates",
  components: {
    VueTypeaheadBootstrap
  },
  props: ['plan', 'create'],
  data() {
    return {
      airports: [],
      alternate: {first: '', second: ''},
      selectedAlternate: {first: null, second: null},
      originalSelected: {first: null, second: null}
    }
  },
  methods: {
    change(key, $event){
      this.selectedAlternate[key] = $event
      const index = key === 'first' ? 0 : 1;
      let alternate = this.plan.alternates[index];
      if(alternate){
        this.backendRemove(key);
      }
      alternate = this.create(this.selectedAlternate[key]);
      this.plan.alternates.push(alternate);
    },
    backendRemove: function (key) {
      const index = key === 'first' || this.plan.alternates.length<2 ? 0 : 1;
      const alternate = this.plan.alternates[index];
			if(!alternate) return;
      this.plan.alternates.splice(index, 1);
      this.removeAlternates([alternate.id]);
    },
    remove(key) {
      this.backendRemove(key);
      this.selectedAlternate[key] = null;
      this.alternate[key] = null;
    },
		/** @param {number[]} ids */
    async removeAlternates(ids) {
      if (ids.length === 0) return;
			await this.$rest.removeAlternates(this.plan.id, ids);
    },
  },
  mounted() {
    this.plan.alternates.forEach((alternate, index) => {
      if (index === 0) {
        this.selectedAlternate.first = this.airports.find(a => a.code === alternate.title);
        this.alternate.first = this.selectedAlternate.first.code;
      }
      if (index === 1) {
        this.selectedAlternate.second = this.airports.find(a => a.code === alternate.title);
        this.alternate.second = this.selectedAlternate.second.code;
      }
    });
    this.originalSelected = Object.create(this.selectedAlternate);
  },
  async created() {
		let mapObjects = await this.$rest.getAllObjects();
    this.airports = mapObjects.filter(object => object.type === "AIRPORT");
  },
}
</script>

<style scoped>

</style>
