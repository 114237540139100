<template>
<div>
  <RoutePlanElementEdit
    v-if="selectedPlan"
    :plan="selectedPlan"
    @successSave="success"
    @back="success"
  ></RoutePlanElementEdit>
</div>
</template>

<script>
import RoutePlanElementEdit from "@/components/routePlan/RoutePlanElementEdit";
import Vue from "vue";
export default {
  name: "RoutePlanElementEditView",
  components: {RoutePlanElementEdit},
  data() {
    return {
      routeId:null,
      selectedPlan: null,
    };
  },
  async mounted() {
    this.routeId = this.$route.params.id;
		this.selectedPlan = await this.$rest.getRoutePlanById(this.routeId);
  },
  methods:{
    success(){
      Vue.set(Vue.prototype, '$rightMenu', []);
      this.$router.push({path: '/route-planning'});
    }
  }
}
</script>

<style scoped>

</style>
