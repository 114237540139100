<template>
  <div class="m-3">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('routePlan.unitSettings') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close',units)" href="javascript:void(0)" class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <label>{{ $t('routePlan.speedUnit') }}</label>
        <select class="form-control" v-model="units.speed">
          <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
          <option v-for="su in speedUnitOptions" :key="su" :value="su">
            {{ su | unitName }}
          </option>
        </select>
      </div>

      <div class="col-md-6">
        <label>{{ $t('routePlan.distanceUnit') }}</label>
        <select class="form-control" v-model="units.distance">
          <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
          <option v-for="su in distanceUnitOptions" :key="su" :value="su">
            {{ su  | unitName}}
          </option>
        </select>
      </div>

      <div class="col-md-6">
        <label>{{ $t('routePlan.consumptionUnit') }}</label>
        <select class="form-control" v-model="units.avgConsumption">
          <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
          <option v-for="su in consumptionUnitOptions" :key="su" :value="su">
            {{ su | unitName }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label>{{ $t('routePlan.magneticCorrection') }}(+/-)</label>
        <div class="input-group">
          <div class="input-group-prepend">
              <select v-model="units.magneticDirection" class="form-control">
                <option :value="-1">{{ $t('routePlan.east') }}</option>
                <option :value="1">{{ $t('routePlan.west') }}</option>
              </select>
          </div>
          <input type="number" class="form-control" v-model="units.magneticCorrection" />
          <div class="input-group-append">
            <div class="input-group-text">°</div>
          </div>
        </div>
      </div>
      <div class="col-12 text-right mt-3">
        <a v-on:click="$emit('close',units)" href="javascript:void(0)" class="btn btn-secondary text-center">
          <font-awesome-icon class="m-auto" icon="check-circle"/>
          {{ $t('routePlan.unitOk') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RoutePlanUnitSettings",
  props: ['speedUnit', 'flightTimeUnit', 'distanceUnit', 'avgConsumptionUnit', 'magneticCorrection', 'magneticDirection'],
  emits: ['close'],
  data() {
    return {
      units: {
        speed: this.speedUnit,
        flightTime: this.flightTimeUnit,
        distance: this.distanceUnit,
        avgConsumption: this.avgConsumptionUnit,
        magneticCorrection: this.magneticCorrection,
        magneticDirection: this.magneticDirection
      },
      speedUnitOptions: Object.keys(this.$units.speed),
      flightTimeUnitOptions: Object.keys(this.$units.time),
      distanceUnitOptions: [
				this.$units.distance.km.key,
				this.$units.distance.mi.key,
				this.$units.distance.nmi.key,
			],
      consumptionUnitOptions: Object.keys(this.$units.consumption),
    }
  },
}
</script>

<style scoped>

</style>
