<template>
	<div>
		<a :class="`sidemenu-item d-flex ${data.help ? 'help-menu-item' : ''}`" href="javascript:void(0)" v-on:click="toggleLinks">
			<span class="flex-1 d-flex align-items-center">
				<font-awesome-icon :icon="data.icon" size="lg" :fixed-width="true" class="mr-2" v-if="data.icon" />
				{{ $t('settings.' + data.title) }}
			</span>
			<div class="d-flex align-items-center" v-if="data.help">
				<div class="help-wrapper">
						<help-button :helpName="data.help" :invertedColor="true" />
				</div>
			</div>
		</a>
		<a class="sidemenu-item align-items-center ml-1 tree-element d-none" href="javascript:void(0)"
			v-for="(element, index) in data.tree" :key="index" v-on:click="element.event">
			<span>{{ $t('settings.' + element.title) }}</span>
		</a>
	</div>
</template>

<script>
import $ from 'jquery'
import HelpModalVue from '@/components/help/HelpModal.vue';
import HelpButton from '@/components/help/HelpButton.vue';
export default {
  components: { HelpButton },
	name: "RightMenuTree",
	props: ['data'],
	methods: {
		toggleLinks(e) {
			const target = e.target;

			if (target.matches(".help-wrapper, .help-wrapper *")) {
				return;
			}
			if ($('.tree-element').hasClass('d-flex')) {
				$('.tree-element').removeClass('d-flex');
				$('.tree-element').addClass('d-none');
			} else {
				$('.tree-element').removeClass('d-none');
				$('.tree-element').addClass('d-flex');
			}
		},

	}
}
</script>

<style scoped>
.hide-tree-element {
	display: none;
}

.show-tree-element {
	display: block;
}

.sidemenu-item {
	border-bottom: 1px solid #ccc;
	color: #000000;
	display: block;
	padding: 0 15px;
}

.sidemenu-item span {
	padding: 20px 0;
}


.sidemenu-item:active,
.sidemenu-item:hover {
	text-decoration: none;
}

.sidemenu-item:hover,
.sidemenu-item:active {
	color: white;
	background: #0468c2;
}

.sidemenu-item:hover .help-wrapper *,
.sidemenu-item:active .help-wrapper * {
	color: white !important;
}

.help-wrapper {
	color: #444;
	cursor: pointer;
	z-index: 9999;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 7px;
}

.help-wrapper:hover {
	color: black;
}
</style>
