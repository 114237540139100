<template><div class="m-3">
  <div class="row">
    <div class="col-10 text-left">
      <h4>{{ $t('routePlan.passengerSettings') }}</h4>
    </div>
    <div class="col-2 text-right">
      <a v-on:click="$emit('close', {cancel:true})" href="javascript:void(0)"
         class="btn btn-warning text-center">
        <font-awesome-icon class="m-auto" icon="times"/>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <label>{{ $t('routePlan.pic') }}: *</label>
      <div class="input-group">
        <input type="text" class="form-control" v-model="plan.pilotInCommand"/>
      </div>
    </div>
    <div class="col-md-6">
      <label>{{ $t('routePlan.passenger') }}: *</label>
      <div class="input-group">
        <input type="text" class="form-control" v-model="plan.passenger"/>
      </div>
    </div>
  </div>
  <div class="col-12 text-right mt-3">
    <a v-on:click="$emit('close',{cancel:true})" href="javascript:void(0)"
       class="btn btn-secondary text-center">
      <font-awesome-icon class="m-auto" icon="check-circle"/>
      {{ $t('routePlan.unitOk') }}
    </a>
  </div>
</div>
</template>

<script>
export default {
  name: "PassengersSettings",
  props: ['plan'],
}
</script>

<style scoped>

</style>
