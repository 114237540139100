<template>
  <div class="m-3">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('routePlan.exactPoint') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="close" href="javascript:void(0)" class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>{{ $t('routePlan.objectSelection') }}:</p>
        <Listbox v-model="selectedObject" :options="objectsInCircle" optionLabel="code" @change="selectSelected" style="border: 2px solid #000000;">
          <template #option="slotProps">
            <div v-if="slotProps.option.distance>-1">
              <span>
								{{$t('objectType.'+slotProps.option.type) }}
								<span v-if="slotProps.option.frequency>0">({{ slotProps.option.frequency }})</span>
								<strong>|</strong>
								{{ slotProps.option.name }} ({{ slotProps.option.code }}) => {{ slotProps.option.distance | formatNumber }} {{ distanceUnit | unitName }}
							</span>
            </div>
            <div v-if="slotProps.option.distance === -1">
              <span>{{ slotProps.option.name }}</span>
            </div>
          </template>
        </Listbox>
      </div>
    </div>
    <div v-if="tooltip" v-html="tooltip" class="mt-3 border p-3" />
  </div>
</template>

<script>
import Listbox from 'primevue/listbox';

export default {
  name: "MapObjectSelect",
  props: ['objectsInCircle', 'tooltip'],
  emits: [],
  components: {Listbox},
  data() {
    return {
			/** @type {DistanceUnitType} */
      distanceUnit: this.$units.distance.km.key,

			/** @type {NavObjectPayload} */
      selectedObject: null
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    selectSelected() {
      if (this.selectedObject == null) {
        alert( this.$t('map.didnt-choose-anything'));
        return;
      }
      this.$emit('close', this.selectedObject)
    },
  }
}
</script>

<style scoped>

</style>
