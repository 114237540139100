<template>
  <div class="m-3">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('settings.fuelSettings') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close',plan)" href="javascript:void(0)" class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
<p>{{$t('routePlan.startFuelInfo')}}</p>
        <div class="form-group">
          <div class="row preview mt-3">
            <div class="col-md-6">
              <label>{{ $t('routePlan.finalReserve') }}: {{plan.finalReserve}}%</label>
              <div class="input-group">
                <input type="range" class="form-control" v-model="plan.finalReserve" min="0" max="100"/>

              </div>
            </div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.contingency') }}: {{plan.contingency}}%</label>
              <div class="input-group">
                <input type="range" class="form-control" v-model="plan.contingency" min="0" max="100"/>
              </div>
            </div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.navReserve') }}: *</label>
              <div class="input-group">
                <input type="number" class="form-control" v-model="plan.navReserve"/>
                <div class="input-group-append">
                  <div class="input-group-text">{{ plan.consumptionUnit | unitSuffix }}</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.extraFuel') }}: *</label>
              <div class="input-group">
                <input type="number" class="form-control" v-model="plan.extraFuel"/>
                <div class="input-group-append">
                  <div class="input-group-text">{{ plan.consumptionUnit | unitSuffix }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        {{ $t('routePlan.totalConsumption') }}: {{totalConsumption | formatNumber}} {{ plan.consumptionUnit | unitSuffix }}<br>
        {{ $t('routePlan.finalReserve') }}: {{plan.avgConsumption* (plan.finalReserve/100) |formatNumber}} {{ plan.consumptionUnit | unitSuffix }}<br>
        {{ $t('routePlan.contingency') }}: {{totalConsumption* (plan.contingency/100) | formatNumber}} {{ plan.consumptionUnit | unitSuffix }}<br>
        <hr>
        SUM: {{totalConsumption + (plan.avgConsumption* (plan.finalReserve/100)) + (totalConsumption* (plan.contingency/100)) +  parseFloat(plan.navReserve) +  parseFloat(plan.extraFuel) | formatNumber}} {{ plan.consumptionUnit | unitSuffix }}
      </div>
      <div class="col-12 text-right mt-3">
        <a v-on:click="$emit('close',plan)" href="javascript:void(0)" class="btn btn-secondary text-center">
          <font-awesome-icon class="m-auto" icon="check-circle"/>
          {{ $t('routePlan.unitOk') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoutePlanConsumptionSettings",
  props: ['plan'],
  data(){
    return {
      totalConsumption:this.calcTotalConsumption()
    }
  },
  methods:{
    calcTotalConsumption(){
      let total = parseFloat(this.plan.avgConsumption)/6;
      this.plan.routePlanElements.forEach(element =>{
        total += element.consumption;
      });
      return total;
    }
  }
}
</script>

<style scoped>

</style>
