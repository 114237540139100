import { render, staticRenderFns } from "./RoutePlanPlaneSettings.vue?vue&type=template&id=2ae0e966&scoped=true"
import script from "./RoutePlanPlaneSettings.vue?vue&type=script&lang=js"
export * from "./RoutePlanPlaneSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae0e966",
  null
  
)

export default component.exports