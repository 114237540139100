<template>
  <div class="m-3">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('settings.planeSettings') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close',plan)" href="javascript:void(0)" class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <div class="form-group">
          <div class="row preview mt-3">
						<div class="col-12">
							<label for="rpName">{{ $t('routePlan.name') }}:</label>
							<input id="rpName" type="text" v-model="plan.name" required class="form-control"/>
						</div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.selectPlane') }}</label>
              <select class="form-control" v-model="selectedPlane"
                      v-on:change="changePlane()">
                <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
                <option v-for="plane in myPlanes" :key="plane.id" :value="plane">
                  {{ plane.registrationNumber }} ({{ plane.model }})
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.planeRegistrationNumber') }}: *</label>
              <input type="text" class="form-control"
                     v-model="plan.planeRegistrationNumber"/>
            </div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.defaultSpeed') }}: *</label>
              <div class="input-group">
                <input type="number" class="form-control" v-model="plan.defaultSpeed"/>
                <div class="input-group-append">
                  <select class="form-control" v-model="plan.speedUnit">
                    <option v-for="unit in speedUnits" :key="unit" :value="unit">
                      {{ unit | unitName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label>{{ $t('routePlan.avgConsumption') }}: *</label>
              <div class="input-group">
                <input type="number" class="form-control" v-model="plan.avgConsumption"/>
                <div class="input-group-append">
                  <select class="form-control" v-model="plan.avgConsumptionUnit">
                    <option v-for="unit in consumptionUnits" :key="unit" :value="unit">
											{{ unit | unitName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 text-right mt-3">
            <a v-on:click="$emit('close',plan)" href="javascript:void(0)" class="btn btn-secondary text-center">
              <font-awesome-icon class="m-auto" icon="check-circle"/>
              {{ $t('routePlan.unitOk') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "RoutePlanPlaneSettings",
  props: ['plan'],
  emits: ['successSave', 'cancel'],
  data() {
    return {
      myPlanes: [],
      selectedPlane: null,
      speedUnits: Object.keys(this.$units.speed),
      consumptionUnits: Object.keys(this.$units.consumption),
    };
  },
  async mounted() {
		const ups = await this.$rest.loadMyUserPlanes() || [];
		this.myPlanes = ups.map(up => up.plane);
		if (!(this.plan.planeRegistrationNumber && this.plan.planeRegistrationNumber.length > 0)) {
			return;
		}

		this.selectedPlane = this.myPlanes.find(p => p.registrationNumber === this.plan.planeRegistrationNumber);
		if (!this.selectedPlane) {
			this.changePlane();
		}
	},
  methods: {
    changePlane() {
			if(!this.selectedPlane) {
				return;
			}
			this.plan.speedUnit = this.selectedPlane.speedUnit || this.plan.speedUnit;
			this.plan.planeRegistrationNumber = this.selectedPlane.registrationNumber || this.plan.planeRegistrationNumber;
			this.plan.avgConsumptionUnit = this.selectedPlane.consumptionUnit ||  this.plan.avgConsumptionUnit;

			this.plan.avgConsumption = Number(this.selectedPlane.defaultConsumption || this.plan.avgConsumption)
			this.plan.defaultSpeed = Number(this.selectedPlane.defaultSpeed || this.plan.avgConsumption);
    },
  }
}
</script>

<style scoped>

</style>
