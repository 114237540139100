<template>
  <div class="container-fluid">
    <div class="row ">
      <div class="col-12 col-md-6 px-3">
        <div class="row">
          <div class="col-12 text-center mt-3">
            <h4>{{ routePlan.name }}</h4>
          </div>
          <div class="col-12 mb-3" style="border-bottom:1px solid #444444">
            <a v-on:click="back" href="javascript:void(0)" class=" mr-2 mb-2 text-center">
              <font-awesome-icon icon="window-close" />
              {{ $t('planes.cancel') }}
            </a>
            <a v-on:click="save(true)" href="javascript:void(0)" class="mr-2 mb-2 text-center">
              <font-awesome-icon class="m-auto" icon="save" />
              {{ $t('planes.save') }}
            </a>
          </div>
          <div class="col-12 mb-3 text-center pb-2 w-100" style="border-bottom:1px solid #444444">
            <label>{{ $t('routePlan.waypoint') }}</label>
            <AutoComplete class="w-100 full-auto-complete" v-model="selectedWaypointTitle" @item-select="$e => addWaypoint($e.value)" field="name" :suggestions="suggestedMapObjects" @complete="searchMapObjects" >
              <template #item="option">
                <div class="d-flex justify-content-between">
									<span>{{ waypointName(option.item) }}</span>
									<span v-if="option.item.distance">
										{{ option.item.distance | formatNumber }} {{ routePlan.distanceUnit | unitSuffix }}
									</span>
								</div>
              </template>
            </AutoComplete>

          </div>
          <div class="col-12 text-center pb-2" style="border-bottom:1px solid #444444">
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="plane" />
              {{ routePlan.planeRegistrationNumber }}
            </button>
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="clock"/>
              {{ totalFlightTime | formatHour }}
            </button>
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="road" />
              {{ totalDistance | formatNumber }} {{ routePlan.distanceUnit | unitSuffix }}
            </button>
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="gas-pump" />
              {{ totalConsumption | formatNumber }} {{ routePlan.consumptionUnit |  unitSuffix }}
            </button>
          </div>
        </div>
        <div class="row" v-if="routePlan.routePlanElements.length > 0">
          <div class="col-12 mt-1">
            <a class="mr-2 mb-2 text-center" v-on:click="deleteAll" href="javascript:void(0)">
              <font-awesome-icon class="m-auto" icon="trash" />
              {{ $t('routePlan.deleteAll') }}
            </a>
            <a class="mr-2 mb-2 text-center" v-on:click="deleteLast" href="javascript:void(0)">
              <font-awesome-icon class="m-auto" icon="backspace" />
              {{ $t('routePlan.deleteLast') }}
            </a>
          </div>
          <div class="col-12 p-0 pl-3 overflow-table">
            <table class="table table-bordered ">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('routePlan.trueTrack') }} ({{ magneticCorrectionUnit | unitName }})</th>
                  <th>{{ $t('routePlan.magneticTrack') }} ({{ magneticCorrectionUnit | unitName }})</th>
                  <th> {{ $t('routePlan.flightTime') }} ({{ this.$t('routePlan.hour-minute') }})</th>
                  <th> {{ $t('routePlan.distance') }}
                    ({{ routePlan.distanceUnit | unitName }})
                  </th>
                  <th>{{ $t('routePlan.consumption') }} ( {{ routePlan.consumptionUnit | unitName }})</th>
                  <th></th>
                </tr>
              </thead>
              <tr>
                <td colspan="7">
                  {{ $t('routePlan.startTitle') }}: {{ routePlan.startElementTitle }}
                </td>
              </tr>
              <RoutePlanElementCardBody :element="element" :route-plan="routePlan" :index="(index)"
                :is-show-settings="true" @reCalc="reCalc" @deleteI="deleteI"
                v-for="(element, index) in routePlan.routePlanElements" :key="index" />
              <RoutePlanElementCardBody bg-color="#ff7300" :element="alternate" :route-plan="routePlan"
                :index="routePlan.routePlanElements.length + '/' + (index === 0 ? 'a' : 'b')" :is-show-settings="false"
                v-for="(alternate, index) in routePlan.alternates" :key="'alternates-' + index" />

            </table>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 p-0 px-md-0 px-3 pl-md-3">
        <div class="d-flex map-row p-0">
          <Map ref="map" @mapClick="mapClick" @markerDragEnd="markerDragEnd" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/map/Map";
import {
	HELPER_FUNCTIONS,
	LOCAL_STORAGE_SERVICE, log,
	ROUTE_PLAN_CALCULATOR,
	STORAGE_KEY,
	TRACK_CEIL,
	UNIT
} from "@/plugins/utils";
import RoutePlanUnitSettings from "@/components/routePlan/routePlanSettings/RoutePlanUnitSettings.vue";
import RightMenuElement from "@/components/menu/menuComponent/RightMenuElement";
import RightMenuTree from "@/components/menu/menuComponent/RightMenuTree";
import RoutePlanPlaneSettings from "@/components/routePlan/routePlanSettings/RoutePlanPlaneSettings.vue";
import RoutePlanAlternates from "@/components/routePlan/routePlanSettings/RoutePlanAlternates.vue";
import PassengersSettings from "@/components/routePlan/routePlanSettings/RoutePlanPassengersSettings.vue";
import RoutePlanConsumptionSettings from "@/components/routePlan/routePlanSettings/RoutePlanConsumptionSettings.vue";
import RoutePlanElementCardBody from "@/components/routePlan/RoutePlanElementCardBody";
import MapObjectSelect from "@/components/map/MapObjectSelect";
import Vue from "vue";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import AutoComplete from "primevue/autocomplete";
import { calcDistance } from "@/plugins/calculator";


export default {
  name: "RoutePlanElementEdit",
  data() {
    return {
			/** @type {RoutePlan} */
      routePlan: this.plan,

			/** @type {LatLong|null} */
      startLatLng: null,

			/** @type {LatLong|null} */
      endLatLng: null,
      clickCount: 0,
      totalDistance: 0,
      totalFlightTime: 0,

			/** @type {AngleUnitType} */
      magneticCorrectionUnit: this.$units.angle.deg.key,
      draggedMarkerOption: null,
      totalConsumption: 0,

			/** @type {NavObjectPayload[]} */
      mapObjects: [],
      /** @type {NavObjectPayload[]} */
      suggestedMapObjects: [],

      dragEnd: 0,

			/** @type {NavObjectPayload} */
      selectedWaypoint: null,
      selectedWaypointTitle: "",
			/** @type {ConsumptionUnitType} */
      oldConsumptionUnit: this.$units.consumption.lh.key,
    };
  },
  components: {
    AutoComplete,
    Map,
    RoutePlanElementCardBody,
  },
  props: ['plan', 'elements', 'msgs'],
  emits: ['back', 'successSave'],
	computed: {
		unitsToWatch() {
			return {
				speedUnit: this.routePlan.speedUnit,
				flightTimeUnit: this.routePlan.flightTimeUnit,
				distanceUnit: this.routePlan.distanceUnit,
				avgConsumptionUnit: this.routePlan.avgConsumptionUnit,
				speed: this.routePlan.defaultSpeed,
				avgConsumption: this.routePlan.avgConsumption,
			}
		},
	},
	watch: {
		unitsToWatch(n, o) {
			if(o.speedUnit !== n.speedUnit && o.speed === n.speed) {
				this.routePlan.defaultSpeed = this.$units.convert(this.routePlan.defaultSpeed, o.speedUnit, n.speedUnit, 2);
			}
			if(o.flightTimeUnit !== n.flightTimeUnit) {
				this.totalFlightTime = this.$units.convert(this.totalFlightTime, o.flightTimeUnit, n.flightTimeUnit, 2);
			}
			if(o.distanceUnit !== n.distanceUnit) {
				this.totalDistance = this.$units.convert(this.totalDistance, o.distanceUnit, n.distanceUnit, 2);
			}
			if(o.avgConsumptionUnit !== n.avgConsumptionUnit && o.avgConsumption === n.avgConsumption) {
				this.routePlan.avgConsumption = this.$units.convert(this.routePlan.avgConsumption, o.avgConsumptionUnit, n.avgConsumptionUnit, 2);
				this.routePlan.consumptionUnit = this.$units.getVolumeUnitFromConsumptionUnit(n.avgConsumptionUnit);
				this.routePlan.navReserve = this.$units.convert(this.routePlan.navReserve, o.avgConsumptionUnit, n.avgConsumptionUnit, 2);
				this.routePlan.extraFuel = this.$units.convert(this.routePlan.extraFuel, o.avgConsumptionUnit, n.avgConsumptionUnit, 2);
			}
			this.reCalc();
		},
	},
  methods: {
    searchMapObjects(event) {
      let lastCoordinates = null;
			const lastElement = this.routePlan.routePlanElements[this.routePlan.routePlanElements.length - 1];
			if(lastElement) {
				lastCoordinates = [lastElement.endLatitude, lastElement.endLongitude];
			}
			else if(this.startLatLng) {
				lastCoordinates = [this.startLatLng.lat, this.startLatLng.lng];
			}
      this.suggestedMapObjects = this.mapObjects
          .filter(obj => {
            return this.waypointName(obj).fullNormalize().trim().startsWith(event.query.fullNormalize());
          })
          .map(obj => {
            let distance = null;
            if(lastCoordinates) {
              distance = this.$units.convert(calcDistance(lastCoordinates[0], lastCoordinates[1], obj.latitude, obj.longitude), 'km', this.routePlan.distanceUnit)
            }
            return {
              ...obj,
              distance,
            }
          });
    },
    async deleteAll() {
			if (!await this.$confirm(this.$t("routePlan.are-u-to-delete-all"))) {
				return;
			}
			const removableElements = this.routePlan.routePlanElements.filter(element => element.id).map(element => element.id);
			if (removableElements.length > 0) {
				await this.remove(removableElements);
			}
			this.$refs.map.clearPlanElements();
			this.clickCount = 0;
			this.reCalc();
		},
		/** @param {number} i */
    async deleteI(i) {
			if (!await this.$confirm(this.$t("routePlan.are-u-sure-to-delete"))) {
				return;
			}

			// Return if the input is out of range
			if(this.routePlan.routePlanElements.length <= i) {
				return;
			}

			// Delete last if the input is the last index
			if (i === (this.routePlan.routePlanElements.length - 1)) {
				this.deleteLast();
				return;
			}

			// Set the new start title to the second element if the input is the first index
			if (i === 0) {
				this.routePlan.startElementTitle = this.routePlan.routePlanElements[i + 1].title;
			}

			// Set the next element's start coordinates to the previous element's end coordinates
			if (i > 0) {
				this.routePlan.routePlanElements[i + 1].startLatitude = this.routePlan.routePlanElements[i - 1].endLatitude;
				this.routePlan.routePlanElements[i + 1].startLongitude = this.routePlan.routePlanElements[i - 1].endLongitude;
			}

			const removableElement = this.routePlan.routePlanElements[i];

			// Remove the element from the array
			this.routePlan.routePlanElements.splice(i, 1);

			if (removableElement.id) {
				await this.remove([removableElement.id], true);
			}
			this.reCalc();
			this.reDraw();
		},
    async deleteLast() {
			// Delete all if there is only one element
      if (this.routePlan.routePlanElements.length < 2) {
        this.deleteAll();
        return;
      }

      const lastIndex = this.routePlan.routePlanElements.length - 1;
      const removableElements = this.routePlan.routePlanElements[lastIndex];
      this.routePlan.routePlanElements.splice(lastIndex, 1);

      if (removableElements.id) {
        await this.remove([removableElements.id], true);
      }
      this.reCalc();
      this.reDraw();

      this.routePlan.alternates.forEach(alternate => {
        this.updateAlternate(alternate);
      });
    },
    reDraw() {
      this.$refs.map.clearPlanElements();

      this.routePlan.routePlanElements.forEach((element, index) => {
				const startLatLong = HELPER_FUNCTIONS.getStartLatLong(element);
				const endLatLong = HELPER_FUNCTIONS.getEndLatLong(element);
        if (index === 0) {
          this.$refs.map.drawMarker(startLatLong, true, {
            draggable: true,
            title: this.routePlan.startElementTitle,
            riseOnHover: true,
            elementIndex: index + "_START"
          });
        }
        this.$refs.map.drawMarker(endLatLong, false, {
          draggable: true,
          title: element.title,
          riseOnHover: true,
          elementIndex: index + "_END"
        });
        this.$refs.map.drawLine([startLatLong, endLatLong], '#00ffe1', TRACK_CEIL(element.magneticTrack).toString());
      });
      const element = this.routePlan.routePlanElements[this.routePlan.routePlanElements.length - 1];
      this.startLatLng = {
				lat: element.endLatitude,
				lng: element.endLongitude
			};
    },
    reCalc() {
      this.totalDistance = 0;
      this.totalFlightTime = 0;
			this.routePlan.routePlanElements = [...this.routePlan.routePlanElements.map((e) => this.calcElementData(e, true))];
			this.routePlan.alternates = [...this.routePlan.alternates.map((e) => this.calcElementData(e))];
      this.totalConsumption = ROUTE_PLAN_CALCULATOR.TOTAL_CONSUMPTION(this.routePlan);
    },
		/** @param {RoutePlanElement} element
		 * @param calcTotal
		 * @returns {RoutePlanElement}
		 * */
    calcElementData(element, calcTotal = true) {
			element.avgConsumption = this.routePlan.avgConsumption;
			element.defaultSpeed = this.routePlan.defaultSpeed;
      element.distance = ROUTE_PLAN_CALCULATOR.DISTANCE(
				this.routePlan.distanceUnit,
				element.startLatitude,
				element.startLongitude,
				element.endLatitude,
				element.endLongitude
			);
      element.flightTime = ROUTE_PLAN_CALCULATOR.FLIGHT_TIME(
        this.routePlan.speedUnit,
				element.defaultSpeed,
        element.startLatitude,
        element.startLongitude,
        element.endLatitude,
        element.endLongitude,
      );
      element.trueTrack = ROUTE_PLAN_CALCULATOR.TRUE_TRACK(
        element.startLatitude,
        element.startLongitude,
        element.endLatitude,
        element.endLongitude
      );
      element.magneticTrack = ROUTE_PLAN_CALCULATOR.MAGNETIC_TRACK(
        element.trueTrack,
        this.routePlan.magneticCorrection
			);
      element.consumption = this.$units.convert(ROUTE_PLAN_CALCULATOR.CONSUMPTION(
				this.routePlan.flightTimeUnit,
				element.flightTime,
				this.routePlan.avgConsumptionUnit,
				element.avgConsumption
			), "l", this.routePlan.consumptionUnit);
      if (calcTotal) {
        this.totalDistance += element.distance;
        this.totalFlightTime += element.flightTime;
      }
      return element;
    },

		/** @param {NavObjectPayload} navObject */
    createElement(navObject) {
			return this.calcElementData({
				title: navObject.title,
				startLongitude: this.startLatLng.lng,
				startLatitude: this.startLatLng.lat,
				endLongitude: this.endLatLng.lng,
				endLatitude: this.endLatLng.lat,
				distance: 0,
				flightTime: 0,
				consumption: 0,
				trueTrack: 0,
				magneticTrack: 0,
				defaultSpeed: this.routePlan.defaultSpeed,
				avgConsumption: this.routePlan.avgConsumption,
				comName: navObject.type === 'AIRPORT' ? navObject.title : "",
				comFreq: navObject.type === 'AIRPORT' ? navObject.frequency : "",
				navName: navObject.type === 'AIRPORT' ? "" : navObject.type === 'RADIO_TRANSMITTER' ? navObject.title : '',
				navFreq: navObject.type === 'AIRPORT' ? "" : navObject.type === 'RADIO_TRANSMITTER' ? navObject.frequency : '',
			});
    },

		/**
		 * @param {NavObjectPayload} airport
		 * @returns {RoutePlanElement}
		 * */
    createAlternate(airport) {
      const lastElement = this.routePlan.routePlanElements[this.routePlan.routePlanElements.length - 1]
			return this.calcElementData({
				title: airport.code,
				startLongitude: lastElement.endLongitude,
				startLatitude: lastElement.endLatitude,
				endLongitude: airport.longitude,
				endLatitude: airport.latitude,
				distance: 0,
				flightTime: 0,
				consumption: 0,
				trueTrack: 0,
				magneticTrack: 0,
				defaultSpeed: this.routePlan.defaultSpeed,
				avgConsumption: this.routePlan.avgConsumption,
				comName: airport.code,
				comFreq: airport.frequency,
				navName: "",
				navFreq: "",
			}, false);
    },

		/**
		 * @param {RoutePlanElement} originalAlternate
		 * @param originalAlternate
		 * @returns {RoutePlanElement}
		 */
    updateAlternate(originalAlternate) {
      const lastElement = this.routePlan.routePlanElements[this.routePlan.routePlanElements.length - 1]

      originalAlternate.startLongitude = lastElement.endLongitude;
      originalAlternate.startLatitude = lastElement.endLatitude;

      return this.calcElementData(originalAlternate, false)
    },

		/** @param {NavObjectPayload} selectedWaypoint */
    addWaypoint(selectedWaypoint) {
      this.selectedWaypoint = selectedWaypoint
      this.addPoi(this.selectedWaypoint);
      this.selectedWaypoint = null;
      this.selectedWaypointTitle = "";
    },

    waypointName(item) {
      if (item.type === 'CITY' || item.type === 'NAVIGATION_POINT') {
        return item.title;
      }
      return item.title + ' (' + item.name + ')';
    },

		/** @param {NavObjectPayload} navObject */
    addPoi(navObject) {
      this.clickCount++;
			// If the first element is not an airport, then the route plan is invalid
      if (navObject.type !== 'AIRPORT' && this.clickCount === 1) {
        alert(this.$t('routePlan.firstElementError'));
      }

			const navObjectLatLong  = HELPER_FUNCTIONS.navObjectToLatLng(navObject)

			// if this is is the first element, then we set the start element
      if (this.clickCount === 1) {
        this.startLatLng = navObjectLatLong;
        this.routePlan.startElementTitle = navObject.title;
        this.routePlan.startComName = navObject.code || navObject.title;
        this.routePlan.startComFreq = navObject.frequency;
      }
			//Otherwise we draw the line and add the element to the route plan
			else {
        this.endLatLng = navObjectLatLong;

        let element = this.createElement(navObject);

        this.$refs.map.drawLine([HELPER_FUNCTIONS.getStartLatLong(element), HELPER_FUNCTIONS.getEndLatLong(element)], '#00ffe1', TRACK_CEIL(element.magneticTrack).toString());
        this.routePlan.routePlanElements.push(element);

        this.startLatLng = navObjectLatLong;
        this.totalConsumption = ROUTE_PLAN_CALCULATOR.TOTAL_CONSUMPTION(this.routePlan);
      }

			// Get the last element index
      const i = Math.max(0, this.routePlan.routePlanElements.length);

			// Draw the marker
      this.$refs.map.drawMarker([navObjectLatLong.lat, navObjectLatLong.lng], (this.clickCount === 1), {
        draggable: true,
        title: navObject.title,
        riseOnHover: true,
        elementIndex: i + (this.clickCount === 1 ? "_START" : "_END")
      });

			// Update the alternate route plane elements
      if (this.routePlan.routePlanElements.length > 0) {
				this.routePlan.alternates = this.routePlan.alternates.map(alt => this.updateAlternate(alt));
        this.save(false);
      }
    },

		/** @param {NavObjectPayload} navObject */
    changePoi(navObject) {
			if (!this.draggedMarkerOption) return;
			const elementIndex = this.draggedMarkerOption.elementIndex.split("_");
			const i = parseInt(elementIndex[0]);


			if (elementIndex[1] === "START") {
				this.routePlan.routePlanElements[i].startLatitude = navObject.latitude;
				this.routePlan.routePlanElements[i].startLongitude = navObject.longitude;

				if (i > 0) {
					this.routePlan.routePlanElements[i - 1].endLatitude = navObject.latitude;
					this.routePlan.routePlanElements[i - 1].endLongitude = navObject.longitude;
				}

				if (i === 0) {
					this.routePlan.startElementTitle = navObject.title;
				}
				else {
					this.routePlan.routePlanElements[i].title = navObject.title;
				}
			} else {
				this.routePlan.routePlanElements[i].title = navObject.title;
				this.routePlan.routePlanElements[i].endLatitude = navObject.latitude;
				this.routePlan.routePlanElements[i].endLongitude = navObject.longitude;

				if (i < this.routePlan.routePlanElements.length - 1) {
					this.routePlan.routePlanElements[i + 1].startLatitude = navObject.latitude;
					this.routePlan.routePlanElements[i + 1].startLongitude = navObject.longitude;
				}
			}

			this.routePlan.alternates = this.routePlan.alternates.map(alt => this.updateAlternate(alt));
			this.reCalc();
			this.reDraw();
			this.save(false);
		},
    setMapCenter() {
      setTimeout(() => {
        this.$refs.map.setRoutePlanCenter([this.routePlan.routePlanElements[0].startLatitude, this.routePlan.routePlanElements[0].startLongitude], 11)
      }, 500);
    },
    back() {
      this.$emit('back', this.routePlan);
    },

		/**
		 * @param {DragEvent} dragEvent
		 */
    markerDragEnd(dragEvent) {
      this.dragEnd++;
			if (this.dragEnd !== 1) return;

			const target = dragEvent.target;
			this.draggedMarkerOption = target.options;
			target.getLatLng()
			this.showMapSelect(target.getLatLng(), this.beforeCloseMapSelectAfterMarkerDrag);
		},

		/**
		 * @param {LatLng} latLng
		 * @param {HTMLElement} tooltip
		 */
    mapClick(latLng, tooltip) {
      this.showMapSelect(latLng, this.beforeCloseMapSelect, tooltip);
    },

		/**
		 * @param {LatLng} latLng
		 * @param {() => void} closeEvent
		 * @param {HTMLElement} [tooltip]
		 */
    showMapSelect(latLng, closeEvent, tooltip) {
			//Filter objects that are not in the 5 km radius
      let onCircleObject = this.mapObjects.filter(obj => {
        obj.distance = ROUTE_PLAN_CALCULATOR.DISTANCE("km", latLng.lat, latLng.lng, obj.latitude, obj.longitude);
				if(obj.distance > 5) return false;

				obj.title = (obj.type === 'CITY' || obj.type === 'NAVIGATION_POINT') ? obj.name : obj.code;
				return true;
      });

			// Sort by distance
      onCircleObject = onCircleObject.sort((a, b) => a.distance - b.distance);

      let poiName = ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LAT(latLng.lat) + " " + ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LNG(latLng.lng);

      if (onCircleObject.length > 0) {
        poiName = onCircleObject[0].name;
      }

      onCircleObject.unshift({
				latitude: latLng.lat,
				longitude: latLng.lng,
				type: 'CUSTOM',
				name: this.$t('routePlan.click-location'),
				distance: -1,
				title: poiName
			});
      const objectsInCircle = onCircleObject;

      this.$modal.show(MapObjectSelect, {
        objectsInCircle: objectsInCircle,
        tooltip: tooltip
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
        'before-close': closeEvent
      });

    },
    showSettings() {
      this.$modal.show(RoutePlanUnitSettings, {
        speedUnit: this.routePlan.speedUnit,
        flightTimeUnit: this.routePlan.flightTimeUnit,
        distanceUnit: this.routePlan.distanceUnit,
        avgConsumptionUnit: this.routePlan.avgConsumptionUnit,
        magneticCorrection: Math.abs(this.routePlan.magneticCorrection),
        magneticDirection: (this.routePlan.magneticCorrection < 0 ? -1 : 1),
        magneticCorrectionUnit: this.magneticCorrectionUnit,
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
        'before-close': this.beforeCloseSettings
      });
    },
    showPlaneSettings() {
      this.$modal.show(RoutePlanPlaneSettings, {
        plan: this.routePlan
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
        'before-close': this.beforeClosePlaneSettings
      });
    },
    showConsumptionSettings() {
      this.$modal.show(RoutePlanConsumptionSettings, {
        plan: this.routePlan
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
        'before-close': this.beforeCloseConsumptionSettings
      });
    },
    showAlternates() {
      this.$modal.show(RoutePlanAlternates, {
        plan: this.routePlan,
        create: this.createAlternate
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
        'before-close': () => this.save(false),
      });
    },
    showPassengersSettings() {
      this.$modal.show(PassengersSettings, {
        plan: this.routePlan
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
				'before-close': () => this.save(false)
      });
    },
    beforeCloseConsumptionSettings(event) {
      this.routePlan = event.params;
      // this.reCalc();
    },
    beforeClosePlaneSettings(event) {
      this.routePlan = event.params
			this.routePlan.routePlanElements = [...this.routePlan.routePlanElements.map(element => {
				return {
					...element,
					defaultSpeed: Number(this.routePlan.defaultSpeed),
					avgConsumption: Number(this.routePlan.avgConsumption),
				}
			})];
      this.reCalc();
    },
    beforeCloseSettings(event) {
      this.routePlan.speedUnit = event.params.speed;
      this.routePlan.flightTimeUnit = event.params.flightTime;
      this.routePlan.distanceUnit = event.params.distance;
      this.routePlan.magneticCorrection = event.params.magneticCorrection * event.params.magneticDirection;
			this.routePlan.avgConsumptionUnit = event.params.avgConsumption;
			this.reCalc()
    },
    beforeCloseMapSelect(event) {
			if (event.params == null) return;
			this.addPoi(event.params);
		},
    beforeCloseMapSelectAfterMarkerDrag(event) {
      this.dragEnd = 0;
      if (event.params != null) {
        this.changePoi(event.params);
				return;
      }
			this.reDraw();
			this.reCalc();
    },
    validation() {
      if (this.routePlan.routePlanElements.length < 1) {
        alert(this.$t('no-point-was-selected'));
        return false;
      }
      if (!this.routePlan.planeRegistrationNumber || this.routePlan.planeRegistrationNumber.length < 1) {
        alert(this.$t('routePlan.no-reg-number'));
        return false;
      }
      return true;
    },
		/**
		 * @param {boolean} withEmit
		 * @param {(...params: any) => void} functionAfterSave?
		 * @param {any} fnAfterSaveParam?
		 */
    async save(withEmit, functionAfterSave = null, fnAfterSaveParam = null) {

      if (!this.validation()) {
        return;
      }
      this.routePlan.routePlanElements.forEach((element, index) => element.orderNumber = (index + 1));
      this.routePlan.dateOfScheduledFlight = HELPER_FUNCTIONS.backEndDateFormat(this.routePlan.dateOfScheduledFlight);
			await this.$rest.updateRoutePlan(this.routePlan);
			await this.$rest.addRoutePlanElements(this.routePlan.id, this.routePlan.routePlanElements);
			const rp = await this.$rest.addAlternates(this.routePlan.id, this.routePlan.alternates);
			if (withEmit) {
				this.$emit('successSave', rp);
			} else {
				this.routePlan = rp;
			}
			this.refreshStore();
			if (functionAfterSave) {
				functionAfterSave(fnAfterSaveParam);
			}
    },
		/**
		 * @param {number[]} ids
		 * @param {boolean} withUpdate
		 */
    async remove(ids, withUpdate = false) {
			const routePlan = await this.$rest.removeRoutePlanElements(this.routePlan.id, ids);
			if(!routePlan) return;

			if(!withUpdate) {
				this.routePlan = routePlan;
				this.refreshStore();
				return;
			}

			const rrp = await this.$rest.addRoutePlanElements(this.routePlan.id, this.routePlan.routePlanElements);
			if(!rrp) return;

			const rp = await this.$rest.addAlternates(this.routePlan.id, this.routePlan.alternates);
			if(!rp) return;

			this.routePlan = rp;
			this.refreshStore();
    },
    refreshStore() {
      const storedRoutePlans = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {});
      storedRoutePlans['rp_' + this.routePlan.id] = this.routePlan;
      LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.ROUTE_PLANE, storedRoutePlans);
    },
		/** @param {boolean} onePage */
    linkToNavlog(onePage) {
      let loggedInUserToken = this.$store.getters.loggedInUserToken;
			if(!loggedInUserToken) return;

			let a = document.createElement('a');
			a.href = `${this.$rest.baseUrl}/route-plan/${this.routePlan.id}/pdf/${onePage ? 'one-page' : 'two-page'}?token=${loggedInUserToken}`;
			a.target = '_blank';
			document.body.appendChild(a);
			a.click();
    },
    async navlogPrint(onePage = false) {
      if (this.routePlan.routePlanElements.length > 0) {
        if (await this.$confirm(this.$t('routePlan.auto-save-answer'))) {
					window.newEvent('NAVLOG', {
						routePlan: String(this.routePlan.id),
						onePage: String(this.routePlan.id),
					})
          this.save(false, this.linkToNavlog, onePage);
        }
      } else {
        alert(this.$t('routePlan.no-route-points'));
      }
    },
    loadMainMenu() {
      Vue.set(Vue.prototype, '$rightMenu', [
        {
          component: RightMenuElement,
          props: {
            title: 'planeSettings', icon: 'plane', event: () => {
              return this.showPlaneSettings();
            },
						help: "plane-settings"
          }
        },
        {
          component: RightMenuElement,
          props: {
            title: 'fuelSettings', icon: 'gas-pump', event: () => {
              return this.showConsumptionSettings();
            },
						help: "fuel-settings",
          }
        },
        {
          component: RightMenuElement,
          props: {
            title: 'showRouteSettings', icon: 'ruler-vertical', event: () => {
              return this.showSettings();
            },
						help: "route-settings",
          }
        },
        {
          component: RightMenuElement,
          props: {
            title: 'addAlternates', icon: 'map-signs', event: () => {
              return this.showAlternates();
            },
						help: "alternates-settings",
          }
        },
        ...( window.platform !== 'web' ?  [] : [{
          component: RightMenuTree,
          props: {
            title: 'printNavlog', icon: 'print',
            tree: [
              {
                title: 'printNavlogOnePage', icon: 'print',
                event: () => {
                  return this.navlogPrint(true);
                }
              }, {
                title: 'printNavlogTwoPage', icon: 'print',
                event: () => {
                  return this.navlogPrint();
                }
              }],
							help: "navlog-settings",
          }
        }]),
        {
          component: RightMenuElement,
          props: {
            title: 'passengerSettings', icon: 'users', event: () => {
              return this.showPassengersSettings();
            },
						help: "crew-settings",
          }
        },
        {
          component: RightMenuElement,
          props: {
            title: 'airspaceSettings', icon: 'layer-group', event: () => {
              return this.$refs.map.menuManager.loadAirspaceMenu(this.loadMainMenu);
            }
          }
        },
        {
          component: RightMenuElement,
          props: {
            title: 'mapSettings', icon: ['far', 'map'], event: () => {
              return this.$refs.map.menuManager.loadMapSettingsMenu(this.loadMainMenu);
            }
          }
        }
      ]);
    },
  },
  mounted() {
    if (this.routePlan.routePlanElements.length > 0) {
      this.setMapCenter();
      this.reDraw();
      // this.reDrawAlternates()
      this.reCalc();
      this.clickCount = this.routePlan.routePlanElements.length;
    }

    this.totalConsumption = ROUTE_PLAN_CALCULATOR.TOTAL_CONSUMPTION(this.routePlan);
    this.oldConsumptionUnit = this.routePlan.consumptionUnit;
    this.loadMainMenu();
  },
  async created() {
		this.mapObjects = await this.$rest.getAllObjects();
		console.log(this.mapObjects);
  },
  beforeCreate() {

  }
}


</script>

<style scoped>
thead {
  position: sticky;
  top: 0;
  border-bottom: 2px solid #ccc;
}

th {
  padding: 10px 5px;
  font-weight: lighter !important;
}

.overflow-table {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

small {
  font-size: 13px;
}

.header-common {
  position: relative;
}

.ab-left {
  position: absolute;
  left: 10px;
  top: 2rem;
}

.map-row {
  flex-grow: 1;
  position: relative;
  height: calc(100vh - 90px);
}
</style>
